import { IMAGES } from "../constent/theme"
import { Link } from "react-router-dom"
import emailjs from "@emailjs/browser"
import toast, { Toaster } from "react-hot-toast"
import { useRef, useState } from "react"

const Footer2 = () => {
  const heartRef = useRef(null)
  const [input, setInput] = useState("")
  const form = useRef(null)
  const sendEmail = e => {
    e.preventDefault()
    setInput("")
    if (form.current) {
      emailjs
        .sendForm(
          "emailId",
          "template_0byuv32",
          form.current,
          "qUDIPykc776NYHv4m"
        )
        .then(
          () => {
            toast.success("Successfully send!")
          },
          error => {
            toast.error(error.text)
          }
        )
    }
  }
  return (
    <>
      <Toaster position="bottom-right" reverseOrder={true} />
      <footer className="site-footer style-2" id="footer">
        <div className="footer-bg-wrapper" id="app-banner">
          <div className="footer-top">
            <div className="container">
            
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 wow fadeInUp">
                  <div className="widget widget_getintuch">
                    <h5 className="footer-title">Contact</h5>
                    <ul>
                      <li>
                        <i className="flaticon-placeholder"></i>
                        <a className="text-white" href="https://www.google.com/maps/place/China+Taste/@29.9631349,-90.2264531,17z/data=!3m1!4b1!4m6!3m5!1s0x8620ba02c7839a03:0xb2c01c11a539614f!8m2!3d29.9631303!4d-90.2238782!16s%2Fg%2F1td5m1g2?entry=ttu&g_ep=EgoyMDI0MTAyMS4xIKXMDSoASAFQAw%3D%3D" target="">
                        10232-B Jefferson Hwy, River Ridge, LA 70123
                        </a>
                      </li>
                      <li>
                        <i className="flaticon-telephone"></i>
                        <a href="tel:+15047397988" className="text-white">
                        (504)-739-7988
                        (504)-739-2223
                        </a>
                      </li>
                      <li>
                      <p className="text-white">
                      Mon - Thur: 10:30-21:00PM<br />
                      Fri: 11:00-21:30PM
                      <br />
            Sat: 10:30-21:30PM          <br />
            Sun: 04:00-9:00PM
      </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-2 col-md-6 col-sm-6">
                  <div className="widget widget_services">
                    <h5 className="footer-title">Our Links</h5>
                    <ul>
                      <li>
                        <Link to="/">
                          <span>Home</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/about">
                          <span>About Us</span>
                        </Link>
                      </li>
                      
                      {/* <li>
                        <Link to="/online-order">
                          <span>Online Order</span>
                        </Link>
                      </li> */}
                      <li>
                        <Link to="/menu">
                          <span>Menu</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                  <div className="widget widget_services">
                    <h5 className="footer-title">OUR SERVICES</h5>
                    <ul>
                  
                      <li>
                        <Link to="/">
                          <span>Top Chefs</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact">
                          <span>Services</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <span>Pickup In Store</span>
                        </Link>
                      </li>
                     
                    </ul>
                  </div>
                </div>
                  
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="footer-bottom">
            <div className="row">
                <span className="copyright-text">
                  Crafted With{" "}
                  <span
                    className="heart"
                    ref={heartRef}
                    onClick={() => {
                      heartRef.current?.classList.toggle("heart-blast")
                    }}
                  ></span>{" "}
                  by{" "}
                  <Link to="/">
                    China Taste
                  </Link>
                </span>
              </div>
              
            </div>
        </div>
      </footer>
    </>
  )
}

export default Footer2
